<!--Display Contentful Rich text content-->
<template>
  <div class="content" v-if="validData">
    <RichTextRenderer :document="document"  :nodeRenderers="renderNode()" />
  </div>
</template>


<script>
  /**
   * This component is used to display Contentful Rich Text.
   */
  //LOCAL methods

  import {mapState, mapGetters, mapActions} from 'vuex'
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
  import {isNil} from 'lodash';

  export default {
    name: 'Error',

    components: {
      RichTextRenderer,
    },

    props: {
      document: Object,
      background: String // One of has-background-primary/success/info/white
    },

    data: function () {
      return {
      }
    },

    computed: {
      validData() {
        return !isNil(this.document);
      }
    },

    /**
     * These render functions override the defaults in contentful-rich-text-vue-renderer to format
     * certain rich text elements the way we want them.
     */
    methods: {
      renderNode() {
        return {

          //BLOCKS
          //embedded entry means a link to some content in contentful.
          // - node gives the current node details
          // - next (if a block) is a method to call with the rendered content to be included

          //embedded asset means a link to some asset in contentful - we assume its an image
          [BLOCKS.EMBEDDED_ASSET]: (node, key, h, next) => {
            // this.$log.debug(`{BLOCKS.EMBEDDED_ASSET} src: `, node.data.target.fields.file.url);
            return h('figure',
                {
                  attrs: {
                    class: "image is-16by9",
                  },
                },
                [
                  h('img',
                      {
                        attrs: {
                          src: `https:${node.data.target.fields.file.url}`,
                        },
                        key: key
                      },
                  )
                ]
            )
          },

          // a blockquote is rendered using Bulma's message with is-info or is-primary colours
          [BLOCKS.QUOTE]:(node, key, h, next) => {
            this.$log.debug(`{BLOCKS.QUOTE} background colour: `, this.background);
            let msgClass = 'is-info';
            switch (this.background){
              case 'has-background-primary':
              case 'has-background-success':
              case 'has-background-info':
                msgClass = 'is-primary';
                break;
              case 'has-background-white':
              default:
                msgClass = 'is-info';
            }
            return h('div',
              {
                attrs: {
                  class: "message "+msgClass,
                },
              },
              [
                h('div',
                  {
                    attrs: {
                      class: "message-body",
                    },
                    key: key
                  },
                  next(node.content, key, h, next)
                )
              ]
            )
          },


          //INLINES
          // a hyperlink is a hyperlink but it may be external
          [INLINES.HYPERLINK]: (node, key, h, next) => {
            this.$log.debug(`{INLINES.HYPERLINK} uri: `, node.data.uri);
            let attrs = {
              href: node.data.uri,
            };
            let text = [node.content[0].value];
            if (node.data.uri.match(/^http:|^https:/)) {
              attrs.target = "external";
              text.push(
                  h('span',
                      { attrs:{
                          class: "icon is-medium"
                        }
                      },
                      [h('font-awesome-icon',
                          { attrs: {
                              icon: ['fal','external-link-alt']
                            }
                          },
                      )]
                  )
              );
            }
            return h('a',
                {
                  attrs,
                },
                text
            )
          },

          // In this case the link is most likely to be a pdf
          [INLINES.ASSET_HYPERLINK]: (node, key, h, next) => {
            this.$log.debug(`{renderNode} ASSET_HYPERLINK gives`, node.data.target, {});
            // Inner rendered text etc.
            const contentType = node.data.target.fields.file.contentType;
            let icon = 'file';
            switch (contentType) {
              case 'application/pdf':
                icon = 'file-pdf';
                break;
            }
            let text = [node.content[0].value]; //asset descriptive text
            text.push(
                h('span',
                    { attrs:{
                        class: "icon is-medium"
                      }
                    },
                    [h('font-awesome-icon',
                        { attrs: {
                            icon: ['far',icon]
                          }
                        },
                    )]
                )
            );
            // Now render link including icon
            const attrsLink = {
              href: node.data.target.fields.file.url,
            };
            return h(
                'a',
                {
                  attrs: attrsLink,
                },
                text
            )
          },


          //NOT USED
          [BLOCKS.EMBEDDED_ENTRY]: (node, next) => {
            this.$log.debug(`{renderNode} EMBEDDED_ENTRY gives`, node.data.target.fields.file.url, {});
          },
          [INLINES.EMBEDDED_ENTRY]: (node) => {
            this.$log.debug(`{renderNode} EMBEDDED_ENTRY gives`, node, {});
          },
          [INLINES.ENTRY_HYPERLINK]: (node) => {
            this.$log.debug(`{renderNode} ENTRY_HYPERLINK gives`, node, {});
          }

        }
      },
      //NOT USED
      // renderMarks() {
      //   return {
      //     [MARKS.BOLD]: (text, key, h) => h('custom-bold', { key: key }, text)
      //   };
      // },
    }
  }

</script>


<style scoped lang="scss">

</style>
